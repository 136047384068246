@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import '~react-icons/fi'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 786px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

/*
 *  STYLE 3
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 50px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: transparent;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: green;
}

.revenue {
  background: rgb(184, 255, 184);
  background: linear-gradient(
    90deg,
    rgba(184, 255, 184, 1) 0%,
    rgba(243, 255, 242, 1) 100%
  );
}
.accessDenied {
  opacity: 0.4;
  cursor: not-allowed;
}

@media (min-width: 786px) {
  .barChart {
    width: calc(100vw- 240px);
  }
}
